import React from "react";
import { Link } from "gatsby";
import { string, oneOfType, object } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

const CaseItem = (props) => {
  const { image, teaser, backgroundColor, linkTo, alt } = props;

  const intl = useIntl();

  return (
    <div className="col-12 col-md-6 mb-3 reveal">
      <div
        className="px-3 pt-3 pb-4 text-center mb-3 h-100 d-flex flex-column justify-content-between align-items-center text-color-white"
        style={{ backgroundColor: backgroundColor }}
      >
        <div className="w-100">
          <img
            src={image}
            className="mx-auto mb-3"
            width="150"
            height="150"
            alt={alt}
          />
          <h3 className="h4">{teaser}</h3>
        </div>
        <Link
          to={`${intl.formatMessage({ id: "nav.references.link" })}/${linkTo}`}
          className="btn mt-3"
        >
          <FormattedMessage id="case.more.text" />
        </Link>
      </div>
    </div>
  );
};

CaseItem.propTypes = {
  backgroundColor: string,
  image: oneOfType([object, string]),
  teaser: string,
  linkTo: string,
  alt: string,
};

CaseItem.defaultProps = {
  backgroundColor: "FF0000",
  teaser: "placeholder teaser",
  linkTo: "/referenzen",
};

export default CaseItem;
